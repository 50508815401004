import './App.css';
import Navbar from './components/Navbar';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import AboutPage from './pages/About';
import WatchPage from './pages/Watch';
import Footer from './components/Footer';
import KnownIssuesPage from './pages/KnownIssues';

function App() {
  //const uiState = {};//TODO: Fix later lmao
  //var available_streams=[];

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<AboutPage />}/>
        <Route exact path="/about" element={<AboutPage />}/>
        <Route exact path="/issues" element={<KnownIssuesPage />}/>
        <Route exact path="/watch" element={<WatchPage />}/>
      </Routes>
    </Router>
  );
}

export default App;
