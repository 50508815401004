import React, { useEffect, useState } from 'react';
import TwitchEmbed from '../components/TwitchEmbed'

class WatchPage extends React.Component {
//function WatchPage(){

    constructor(props) {
        super(props);
        this.state = {available_streams: [], current_stream:""};

        this.hasAttachedEvents= false;
        this.initIntervalID=0;
        this.newStreamIntervalID=0;
        this.waitingForNewStreams= false;
        
      }

    componentDidMount()
    {
        const apiUrl = "https://api.indefinite.tv/api/getstream";
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => this.setState({available_streams: data,current_stream: data[0]}));
        if(this.state.available_streams.length <= 0)
        {
            console.log("No streams live at the moment!");
            let rname = setInterval(this.nextStream, 60 * 1000);
            this.newStreamIntervalID=rname;
            this.waitingForNewStreams=true;
        }
        //this.setState(this.state.current_stream=this.state.available_streams[0] || "bluebackbite");
        console.log("current stream", this.state.current_stream);
        let dname=setInterval(this.initListeners, 15000);
        this.initIntervalID=dname;
        console.log("thisNV",this.nv);
        //this.nv.props['script'].addEventListener('ended', this.nextStream);
    }

    componentWillUnmount() 
    {
        clearInterval(this.initIntervalID);
        clearInterval(this.newStreamIntervalID);
        var embed = document.getElementById('twitch-embed');
        embed.removeEventListener('ended', this.nextStream);
        embed.removeEventListener('offline', this.nextStream);
    }

    initListeners()
    {
        var embed = document.getElementById('twitch-embed');
        var tprops=window.Twitch;
        if(tprops === undefined)
        {
            console.log("Twitch player is undefined or not found?")
        }
        else
        {
            //console.log("Twitch player found? Object:");
            //console.log("this.state",this.state);
            if(!this.hasAttachedEvents)
            {
                embed.addEventListener(tprops.Embed.ENDED, this.nextStream);
                embed.addEventListener(tprops.Embed.OFFLINE, this.nextStream);
                //this.nv.addEventListener(embed.Embed.ENDED, this.nextStream);
                console.log("attached listen events to player for stream ended.");
                this.hasAttachedEvents=true;
                clearInterval(this.initIntervalID);
            }
        }
    }

    nextStream()
    {
        const apiUrl = "https://api.indefinite.tv/api/getstream";
        let known_data = [];
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => known_data = data);
        this.setState({available_streams: known_data});
        
        if(known_data.length <= 0)
        {
            console.log("There are no live streams at the moment :c");
            if(!this.waitingForNewStreams)
            {
                console.log("No streams live at the moment, still waiting...");
                let rname = setInterval(this.nextStream, 60 * 1000);
                this.newStreamIntervalID=rname;
                this.waitingForNewStreams=true;
            }
        }
        else
        {
            if(this.waitingForNewStreams)
            {
                clearInterval(this.state.newStreamIntervalID);
                this.waitingForNewStreams=false;
                this.newStreamIntervalID=0;
            }

            //Change TwitchEmbed component to new stream.
            //Does this happen automagically?
            if(this.nv.props.channel !== known_data[0])
            {
                console.log("Now watching", known_data[0]);
                this.nv.props.channel=known_data[0];
            }
        }
    }


    render() {
        return (
            <div className="py-0 px-0 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                    Channel auto-switching is WIP. Youtube content integration coming soon&trade;.
                    </p>
                </div>
              </div>
              <TwitchEmbed channel={this.state.available_streams[0]} ref={elem => this.nv = elem} />
            </div>
        )
    }
}//ref={twitchembed => (this.embed = twitchembed)}

export default WatchPage;