import React from "react";




const EMBED_URL = 'https://embed.twitch.tv/embed/v1.js';

class TwitchEmbed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }


  componentDidMount() {
    let embed;
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      EMBED_URL
    );
    script.addEventListener('load', () => {
      embed = new window.Twitch.Embed(this.props.targetID, { ...this.props });
    });
    //script.addEventListener('ended',() => {console.log("Stream ended")});
    //script.addEventListener('error',() => {console.log("An error occored")});
    //script.addEventListener('offline',() => {console.log("Streamer went offline")});
    document.body.appendChild(script);

  }


  render() {

    return (
      
        <div id={this.props.targetID} className="h-screen">
        </div>
      
    )
  }
}

TwitchEmbed.defaultProps = {
  targetID: 'twitch-embed',
  width: '100%',
  height: '100%',
  autoplay: true,
  //allowfullscreen: true,
  channel: 'bluebackbite',
  parent: ["watch.indefinite.tv", "indefinite.tv"],
}

export default TwitchEmbed;