/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon, UsersIcon } from '@heroicons/react/outline'


const features = [
  {
    name: 'Configurable content tiers',
    description:
      'Configure the things you want to watch. Currently only twitch is supported, with youtube and facebook content planned to come. TL;DR: Your own configurable classic auto-host for watching.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Endless mode',
    description:
      'If you don\'t want things to stop, why not enable the discovery queue and support small streamers with your views.',
    icon: ScaleIcon,
  },
  {
    name: 'Shoes can\'t get pregnant',
    description:
      'because they\'re shoes, and feet are dirty and gross.',
    icon: UsersIcon,
  },
  {
    name: 'Patreon',
    description:
      'For the time being, this project is ad-free and patreon supported. It would take a considerable amount of money for any corporate entity to try and buy me/this out.',
    icon: AnnotationIcon,
  },
]

export default function AboutPage() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Indefinite TV</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Always something to watch
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            More content is streamed to the web than are eyeholes to watch it. Indefinite TV is a way to organize the content you watch.
             Whether you're using it as background noise, or catching up on closely followed content.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
        
        <div className="lg:text-center">
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            If you found this useful, perhaps consider sending a dollar my way <a href="https://www.patreon.com/BlueBackbite" className="underline decoration-pink-500 hover:decoration-inherit transition duration-300 ease-in-out">via patreon</a>.
          </p>
        </div>
        
      </div>
    </div>
  )
}