/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon, UsersIcon } from '@heroicons/react/outline'


const features = [
  {
    name: 'Purple Banner / best experience on twitch',
    description:
      `If you, or an extension of your browser, is blocking trackers / ads, you will likely see the big purple screen saying that "This content is best viewed on twitch." You can read about it on the twitch developer forums if you wanna see twitch staff get roasted.`,
    icon: LightningBoltIcon,
  },
  {
    name: 'General bugs',
    description:
      `This project is pre-alpha stage as far as I'm concerned. Yeah, you'll see some bugs, I probably already know about them.`,
    icon: GlobeAltIcon,
  },
  {
    name: 'pidgeon',
    description:
      'SQWAAAK.',
    icon: UsersIcon,
  },
  {
    name: '???',
    description:
      'proffit.',
    icon: AnnotationIcon,
  },
]

export default function KnownIssuesPage() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Indefinite TV</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            KNOWN BUGS/ISSUES
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
        
      </div>
    </div>
  )
}